<template>
	<div class="FM_page-forgetPassword">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 offset-lg-4 col-xl-4 offset-xl-4">
					<h1 class="FM_logo-login text-center">
						<router-link to="/">
							<img src="~@/assets/logo/logo-h.png" class="img-logo" width="100%">
						</router-link>
					</h1>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form class="FM_loginBox" autocomplete="off" @submit.prevent="handleSubmit(submit)">
							<h2 class="text-title">{{ $t('__forgotYourPassword') }}?</h2>
							<p class="text-subTitle">{{ $t('__descForgotPwd') }}</p>
							<div class="alert alert-danger" role="alert" v-show="formStatus.success === 0">
								{{ formStatus.errorMsg }}
							</div>
							<div class="form-group">
								<ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
									<input type="text" v-model="form.email" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" placeholder="Email">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<button type="submit" class="btn btn-success btn-block FM_btn FM_submit-account" :disabled="formStatus.spinner">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="formStatus.spinner"></span>
								{{ $t('__submit') }}
							</button>
						</form>
					</ValidationObserver>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { getStorage } from "@/utils/storage.js"
import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import CN from 'vee-validate/dist/locale/zh_CN.json'
import { localize } from 'vee-validate'

export default {
	name: "ForgetPassword",
	data(){
		return{
			form: {
				email: ''
			},
			formStatus: {
				spinner: false,
				success: null, // 0:fail / 1:success / null: not logined
				errorMsg: ''
			}
		}
	},
	created() {
		if (getStorage('lang') === 'cn') {
			localize('zh_CN', CN);
		}
		extend('email', email);
		extend('required', required);
	},
	methods: {
		resetStatus() {
			this.formStatus = {
				spinner: false,
				success: null, // 0:fail / 1:success / null: not logined
				errorMsg: ''
			}
		},
		submit() {
			this.resetStatus();
			this.$Progress.start();
			this.formStatus.spinner = true;

			let _data = {
				account: this.form.email,
			};

			Ajax(
				API.forgetPassword.method,
				API.forgetPassword.url,
				_data
			).then(async (result) => {
				this.formStatus.spinner = false;
				this.$Progress.finish();

				if(result.success !== 1) {
					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
					return ;
				}

				alert('Please go to the Email to receive the reset password letter.');
				await this.$router.push('/');
			})
		}
	}
}
</script>

<style scoped>

</style>